import { graphql } from 'gatsby'
import * as React from 'react'
import CTA from '../components/cta'
import FeatureBanner from '../components/featureBanner'
import FeatureProjects from '../components/featureProjects'
import Header from '../components/header'
import Introduction from '../components/introduction'
import Layout from '../components/layout'
import NewsCarousel from '../components/newsCarousel'
import ScrollingBanner from '../components/scrollingBanner'
import SEO from '../components/seo'
import Spacer from '../components/spacer'
import { toggleMenu } from '../lib/toggleMenu'

class IndexPage extends React.Component {
	componentDidMount() {}
	render() {
		const data = this.props.data.allDatoCmsHomepage.edges[0].node

		const news = this.props.data.news.edges
		const seo = data.seo

		return (
			<Layout>
				<h1 className='hide'>SIERA: Your Trusted Partner in Brisbane Property Development</h1>
				<SEO title={seo?.title || 'Built for Generations'} description={seo?.description} image={seo?.image?.url} />
				<Header page='Home' className='is-dark' />
				<Introduction noPlay={this.props.entry.state.internal} images={data.introImages} />
				<ScrollingBanner videoClass='filter-darken' position='pos-rel' video={data.videoUrl} title={data.heroTitle}>
					<>
						<p className='text-will-change is-dark fill-transition'>ABOUT US</p>
						<p className='h3 max-500 text-will-change is-dark fill-transition'>{data.heroContent}</p>
					</>
				</ScrollingBanner>
				<Spacer />
				<FeatureProjects left={data.largeFeaturedProject} right={data.smallFeaturedProject} />
				<div className='p40  '>
					<div className='bt1 max-1400 ma background-will-change'></div>
				</div>
				<FeatureBanner className='m-mb80' videoUrl={data.videoUrlHome} title={data.ctaTitle} button={data.ctaButton} link={data.ctaLink} image={data.ctaImage} />
				<NewsCarousel articles={news} />
				<CTA clicked={toggleMenu} image={data.contactImage} link='/contact' title='Want more information' button='Contact Us' />
			</Layout>
		)
	}
}

export default IndexPage

export const query = graphql`
	query Homepage {
		allDatoCmsHomepage {
			edges {
				node {
					videoUrl
					introImages {
						gatsbyImageData
					}

					heroTitle
					heroContent
					largeFeaturedProject {
						title
						featureImage {
							gatsbyImageData
						}
						location
					}
					smallFeaturedProject {
						title
						featureImage {
							gatsbyImageData
						}
						location
					}
					ctaButton
					ctaTitle
					videoUrlHome
					ctaLink
					ctaImage {
						gatsbyImageData
					}
					contactImage {
						gatsbyImageData
					}
					seo {
						description
						title
						image {
							url
						}
					}
				}
			}
		}
		news: allDatoCmsArticle(sort: { fields: date, order: ASC }) {
			edges {
				node {
					year
					location: publication
					info
					id
					title: date
					link
					featureImage: image {
						gatsbyImageData
					}
				}
			}
		}
		allDatoCmsProject(filter: { featureProject: { eq: true } }) {
			edges {
				node {
					title
					featureImage {
						gatsbyImageData
					}
					location
				}
			}
		}
	}
`
